import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import { galleries } from '../projectsData';
import { projectsData } from '../projectsData'
import Glider from 'glider-js';

const Project = ({ location }) => {
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const currentProjectImages = galleries[id]
  const currentProject = projectsData.find(project => project.id === id);
  const currentProjectData = currentProject ? currentProject : {};

  const initSlider = () => {
    new Glider(document.querySelector('.glider'), {
      slidesToShow: 1,
      dots: '.glider__dots',
      draggable: true,
      arrows: {
        prev: '.glider-prev',
        next: '.glider-next'
      }
    });
  }

  useEffect(() => {
    initSlider();
  });

  if (!currentProjectData || !currentProjectData.stats) {
    // Handle the case where currentProjectData or its stats property is undefined or null
    return <div></div>;
  }

  return (
    <Layout fullMenu>
      <article id="main">
        <section id="two" className="wrapper alt style2">
          <section className="spotlight">
            <div className="content">
            </div>
          </section>
          <section className="spotlightWithImage">
            <div className="content">
              <h2>
                {currentProjectData.title}
              </h2>
              <div className="stats">
                <div className="stats-sub-div">
                  {Object.keys(currentProjectData.stats).map((stat) => (
                    <div key={stat} className="stats-ind">
                      {/* <header className="stats-header">{stat.toUpperCase()}</header> */}
                      <span className="stats-span">{currentProjectData.stats[stat]}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="image">
              {currentProjectImages.length > 0 &&
                <div className="testimonial__slider" data-sal="fade" data-sal-easing="ease-in-cubic">
                  <div className="glider">
                    {
                      currentProjectImages.map((path) => (
                        <div className="testimonial">
                          {/* <div className="testimonial__content"> */}
                          <img src={path} alt="" />
                          {/* </div> */}
                        </div>
                      ))
                    }
                  </div>
                  <button className="glider-prev material-icons">keyboard_arrow_left</button>
                  <button className="glider-next material-icons">keyboard_arrow_right</button>
                  <div className="glider__dots"></div>
                </div>
              }

            </div>
          </section>
          <section className="spotlight">
            <div className="colorSitePlan">
              {currentProjectData.colorSitePlan &&
                <div className="colorSitePlanImage">
                  <img className="colorSitePlanImg" src={currentProjectData.colorSitePlan} alt={"colored site plan"} />
                </div>}
              <div className="colorSitePlanDescription">
                <p>
                  {currentProjectData.description}
                </p>
              </div>
            </div>
          </section>
        </section>
      </article>
    </Layout >
  );
};

export default Project;
